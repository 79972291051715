<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	export default {
		components: {},
		data() {
			return {}
		},
		mounted() {

			// 网站配置接口
			this.config()

			// 友盟统计添加
			const script = document.createElement("script")
			script.src =
				"https://s4.cnzz.com/z_stat.php?id=1281082690&web_id=1281082690" //该路径为点击统计代码后，页面中第一个 文字统计 script代码
			script.language = "JavaScript"
			document.body.appendChild(script)
		},
		methods: {
			async config() {
				const res = await this.request({
					method: "get",
					url: "https://admin.iptzx.com/api/index/config",
					data: {}
				})
				console.log(res)
				document.title=res.data.data.website.shop_name
				localStorage.setItem('icp',res.data.data.copyright[0].key)
				localStorage.setItem('icpUrl',res.data.data.copyright[0].value)
				localStorage.setItem('wab',res.data.data.copyright[1].key)
				localStorage.setItem('wabUrl',res.data.data.copyright[1].value)
				// 动态修改网页icon
				// document.querySelectorAll("link[rel*='icon']").forEach(item => {
				//      item.href = res.data.data.h5_favicon;
				// })
			},
		},
		watch: {
			$route() {
				if (window._czc) {
					let location = window.location
					let contentUrl = location.pathname + location.hash
					let refererUrl = "/"
					window._czc.push(["_trackPageview", contentUrl, refererUrl])
				}
			},
		},
	}
</script>

<style lang="less">
	#app {
		overflow-x: hidden;
	}

	.ant-steps-item-container {
		width: 200px;
	}

	.ant-carousel {
		width: 100%;
	}

	.ant-carousel .slick-dots li.slick-active button {
		background: #0a49ff !important;
	}

	.ant-carousel .slick-dots li button {
		background: #eee !important;
	}

	// .ant-form-item {
	//   // margin-right: 120px;
	// }
	.ant-col-sm-6 {
		width: 20%;
	}
</style>