import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import axios from 'axios'
import request from './utils/request';
import animated from 'animate.css';
import './global.css'

// import './style/header.css'
// import './style/indexContent.css'
// import './style/footer.css'
// import './style/login.css'
// import './style/my.css'

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.request = request
Vue.use(Antd);
Vue.use(animated)


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
